import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const destinations = [
  {
    name: "Cyprus",
    image: <StaticImage src="../images/cyprus.jpg" alt="Cyprus" />,
    link: "/destination/cyprus",
  },
  {
    name: "Tunisia",
    image: <StaticImage src="../images/tunisia.jpg" alt="Tunisia" />,
    link: "/destination/tunisia",
  },
  {
    name: "Saint Lucia",
    image: <StaticImage src="../images/st-luicia.jpg" alt="Saint Lucia" />,
    link: "/destination/st-luicia",
  },
  {
    name: "Croatia",
    image: <StaticImage src="../images/croatia.jpg" alt="Croatia" />,
    link: "/destination/croatia",
  },
]

const DestinationThumbNail = ({ name, image, link }) => {
  return (
    <div>
      <Link to={link}>
        <h2>{name}</h2>
        {image}
      </Link>
    </div>
  )
}

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Dream Travel</h1>
    <div>
      {destinations.map(destination => (
        <DestinationThumbNail key={destination.link} {...destination} />
      ))}
    </div>
  </Layout>
)

export default IndexPage
